import React from 'react';

import Page from 'components/Page';
import ContentSection from 'components/ContentSection';
import CenterContent from 'components/CenterContent';
import SectionIntro from 'components/SectionIntro';
import Button from 'components/Button';

const metaTags = {
  title: 'datablocks - page not found',
  siteUrl: 'https://datablocks.pro/404',
  robots: 'noindex, nofollow',
};

const NotFound = () => {
  return (
    <Page theme="dark" metaTags={metaTags}>
      <ContentSection>
        <CenterContent>
          <SectionIntro
            title="Page not found."
            text={
              <Button
                as="a"
                style={{ width: 200, margin: '80px auto', display: 'block' }}
                type="big"
                href="/"
              >
                return to homepage
              </Button>
            }
          />
        </CenterContent>
      </ContentSection>{' '}
    </Page>
  );
};

export default NotFound;
